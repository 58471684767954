// namespace is the key on the 'window' object where the app's environment variables
// are stored. The value of namespace should match the values found in the various

import { IndexableObjectType } from 'utils/types';

// <environment>.env.js files.
const namespace = '_env_im_ui';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const envVars = window[namespace] || {};

export const {
  ENV_NAME,
  NODE_ENV,
  AUTH_SERVER_URL,
  APP_NAME,
  APP_CLIENT_ID,
  AUTH_HOST,
  API_URL,
  IM_API_BACKEND,
  WORKFLOWS_URL,
  MARKETPLACE_URL,
}: IndexableObjectType = envVars;
